<template>
    <div class='kolAudit'>
        <el-dialog class="zc_dialog_box" :visible.sync="dialogVisible" width="640px" top="10vh" :close-on-click-modal='false' @close='cancelBtn'>
            <div slot="title">
                <div class="name">{{ title }}</div>
            </div>
            <div class="details_info" v-loading='loading'>
                <div class="info_box">
                    <div class="info_item w50" v-for="(item, index) in infoList1" :key="index">
                        <div class="label" :class="type == 1 ? 'label1' : 'label4'">
                            <span v-if="item.required">*</span>
                            {{ item.label }}
                        </div>
                        <div class="value">{{ item.value }}</div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="">
                    <div class="info_item" v-for="(item, index) in infoList2" :key="index">
                        <div class="label label2">
                            <span v-if="item.required">*</span>
                            {{ item.label }}
                        </div>
                        <div class="value">
                            <div class="txt" v-if="index == 0 || index == 1">{{ item.value }}</div>
                            <img v-if="index == 0 || index == 1" class="copy" @click="copyBtn(item.value)" src="@/assets/images/user/copy.png" alt="">
                            <div v-if="index == 2" class="img" @click="handleImgPreview(item.value)">
                                <el-image style="width: 100%; height: 100%" :src="item.value" fit="cover"></el-image>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="info_box">
                    <div class="info_item w50" v-for="(item, index) in infoList3" :key="index">
                        <div class="label label3">
                            <span v-if="item.required">*</span>
                            {{ item.label }}
                        </div>
                        <div class="value">
                            <el-link v-if="index == 4" :href='item.value' type="primary">电子签约</el-link>
                            <div v-else class="txt">{{ item.value }}</div>
                        </div>
                    </div>
                    <div class="time_box">
                        <div class="label" style="width: 120px;">
                            <span>*</span>
                            有效期时间段：
                        </div>
                        <div class="value">
                            <el-date-picker size="small" v-model="rzTimes" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :clearable="false">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <el-button class="btnBgColor_grey" size="small" @click="refuseBtn">拒绝</el-button>
                <el-button class="btnBgColor_blue" size="small" :loading='qdLoading' type="primary" @click="affirmBtn">确定</el-button>
            </div>
        </el-dialog>
        <!-- 图片预览 -->
        <el-dialog :visible.sync="imgPreview" :close-on-click-modal='false'>
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
        <!-- 驳回 -->
        <el-dialog class="zc_dialog_box" width="624px" :visible.sync="refuseLayer" :close-on-click-modal='false'>
            <div slot="title">
                <div class="name">KOL审核</div>
            </div>
            <div class="inv_main">
                <div class="label">驳回原因：</div>
                <div class="value">
                    <el-input type="textarea" v-model="refuseReason" placeholder="请输入驳回原因" maxlength="20"
                        :autosize="{ minRows: 6, maxRows: 6}" show-word-limit></el-input>
                </div>
            </div>
            <div slot="footer">
                <el-button class="btnBgColor_grey" size="small" @click="closeBtn">取消</el-button>
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="refuseConfirmBtn"
                    :loading="subLoading">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    components: {},
    props: {},
    data() {
        return {
            type:1,
            dialogVisible: false,
            loading:false,
            qdLoading:false,
            subLoading: false,
            id: '',
            info:'',
            imgUrl: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg', // 粉丝流量截图
            imgPreview: false,
            dialogImageUrl: '',
            rzTimes: [],
            startTime: '',
            endTime: '',
            refuseLayer: false, // 驳回
            refuseReason: '', // 驳回原因
        }
    },
    computed: {
        title() {
            return 'KOL审核'
        },
        infoList1() {
            let infoList = []
            if (this.type == 1) {
                infoList = [
                    {
                        label: '身份：',
                        value: '个人',
                        required: true,
                    },
                    {
                        label: '姓名：',
                        value: this.info.name,
                        required: true,

                    },
                    {
                        label: '登录手机号：',
                        value: this.info.member?.mobile,
                        required: true,

                    },

                    {
                        label: '身份证号：',
                        value: this.info.idc,
                        required: true,

                    },
                ]
            }
            if (this.type == 2) {
                infoList = [
                    {
                        label: '身份：',
                        value: '企业',
                        required: true,
                    },
                    {
                        label: '企业名称：',
                        value: this.info.corp_name,
                        required: true,

                    },
                    {
                        label: '登录手机号：',
                        value: this.info.member?.mobile,
                        required: true,

                    },
                    {
                        label: '统一社会信息代码：',
                        value: this.info.credit_code,
                        required: true,

                    },
                    {
                        label: '法人代表姓名：',
                        value: this.info.name,
                        required: true,

                    },
                    {
                        label: '法人代表身份证号：',
                        value: this.info.idc,
                        required: true,

                    },

                ]
            }
            return infoList
        },
        infoList2() {
            let infoList = [
                {
                    label: '视频平台及账号ID：',
                    value: this.info.video_account,
                    required: true,

                },
                {
                    label: 'PC版个人主页链接：',
                    value: this.info.home_url,
                    required: true,

                },
                {
                    label: '上传视频平台粉丝数量截图：',
                    value: this.info.fan_screen,
                    required: true,

                },
            ]
            return infoList
        },
        infoList3() {
            let infoList = [
                {
                    label: '推荐人：',
                    value: this.info.referrer_mobile||'无',
                    required: true,
                },
                {
                    label: '注册时间：',
                    value: this.info.member?.register_time,
                    required: false,
                },
                {
                    label: '来源：',
                    value: this.info.member?.source_name || '--',
                    required: true,
                },
                {
                    label: '提交入驻时间：',
                    value: this.info.create_time,
                    required: false,
                },
                {
                    label: '签约：',
                    value: this.info.sign_url,
                    required: false,
                },
            ]
            return infoList
        },
    },
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开弹框
        openDialogBtn(id) {
            this.dialogVisible = true
            this.id = id
            this.getDetails()
        },
        getDetails() {
            this.loading = true
            this.$userApi.getKolApplyDetails(this.id).then(res => {
             this.loading = false
            	if (res.code == 1000) {
            		this.info = res.result
                this.type=this.info.type
            	} else {
            		this.$errMsg(res.message)
             }
            })
        },
        // 取消
        cancelBtn() {
            this.dialogVisible = false
            this.id=''
            this.info=''
            this.rzTimes=[]
            this.refuseReason=''
        },
        // 拒绝
        refuseBtn() {
            this.refuseLayer = true
        },
        // 确定
        affirmBtn() {
            if(this.rzTimes.length==0){
              this.$errMsg('请选择有效时间段')
              return
            }else{
              const data = {
                  id: this.id,
                  createper: `${this.rzTimes[0]}|${this.rzTimes[1]}`,
                  status: 2,
              }
              this.qdLoading = true
              this.$userApi.kolApplyAudit(data).then(res => {
                  this.qdLoading = false
                  if (res.code == 1000) {
                      this.$succMsg(res.message)
                      this.cancelBtn()
                      this.$emit('refreshList')
                  } else {
                      this.$errMsg(res.message)
                  }
              })
            }
        },
        // 图片预览
        handleImgPreview(url) {
            this.dialogImageUrl = url;
            this.imgPreview = true;
        },
        closeBtn() {
            this.refuseLayer = false
        },
        refuseConfirmBtn() {
          if(this.refuseReason==''){
            this.$errMsg('请输入驳回原因')
            return
          }else{
            const data = {
                id: this.id,
                status: 3,
                remarks:this.refuseReason
            }
            this.subLoading = true
            this.$userApi.kolApplyAudit(data).then(res => {
                this.subLoading = false
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.closeBtn()
                    this.cancelBtn()
                    this.$emit('refreshList')
                } else {
                    this.$errMsg(res.message)
                }
            })
          }
        },
        // 复制
        copyBtn(val) {
            this.$copyText(val).then(
                (e) => {
                    this.$message.success("复制成功");
                },
                function (e) { }
            );
        },
    },
}
</script>

<style lang='scss' scoped>
.kolAudit {
    .details_info {

        .info_box {
            display: flex;
            flex-wrap: wrap;

        }

        .info_item {
            display: flex;

            margin-bottom: 20px;

            .label {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;

                span {
                    color: #E00C25;
                }
            }

            .label1 {
                width: 100px;
            }

            .label2 {
                width: 198px;
            }

            .label3 {
                width: 108px;
            }

            .label4 {
                width: 140px;
            }

            .value {
                flex: 1;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                display: flex;
                align-items: center;

                .img {
                    width: 130px;
                    height: 86px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .copy {
                    cursor: pointer;
                    width: 16px;
                    height: 16px;
                    margin-left: 5px;
                }
            }
        }

        .w50 {
            width: 50%;
        }

        .time_box {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .label {
                width: 108px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;

                span {
                    color: #E00C25;
                }
            }

            .value {
                width: 312px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }
        }

        .line {
            width: 100%;
            height: 2px;
            background: #D8D8D8;
            margin: 10px 0 30px 0;
        }
    }
    .inv_main {
        .label {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }
        .value{
            margin-top: 16px;
        }
    }
}
</style>
