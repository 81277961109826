<template>
    <div class='kolEdit'>
        <el-dialog class="zc_dialog_box" :visible.sync="dialogVisible" width="714px" top="10vh" :close-on-click-modal='false'>
            <div slot="title">
                <div class="name">{{ title }}</div>
            </div>
            <div class="details_info" v-loading='loading'>
                <div class="info_box">
                    <div class="info_item" v-for="(item, index) in infoList1" :key="index">
                        <div class="label" :class="type == 1 ? 'label1' : 'label4'">{{ item.label }}</div>
                        <div class="value">{{ item.value }}</div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="info_box">
                    <div class="info_item" v-for="(item, index) in infoList2" :key="index">
                        <div class="label">{{ item.label }}
                        </div>
                        <div class="value">
                            <el-link v-if="index == 3" :href='item.value' type="primary">电子签约</el-link>
                            <div v-else-if="index == 4" class="img" @click="handleImgPreview(item.value)">
                                <el-image style="width: 100%; height: 100%" :src="item.value" fit="cover"></el-image>
                            </div>
                            <div v-else class="txt">{{ item.value }}</div>
                            <img v-if="index == 0 || index == 1" class="copy" @click="copyBtn(item.value)" src="@/assets/images/user/copy.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="info_box">
                    <div class="info_item" v-for="(item, index) in infoList3" :key="index" v-if="item.show">
                        <div class="label label3">{{ item.label }}</div>
                        <div class="value">{{ item.value }}</div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="info_box">
                    <div class="time_box" v-for="(item, index) in infoList4" :key="index" v-if="item.value">
                        <div class="label">{{ item.label }}</div>
                        <div class="value">{{ item.value }}</div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!-- 图片预览 -->
        <el-dialog :visible.sync="imgPreview" :close-on-click-modal='false'>
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>

export default {
    components: {},
    props: {},
    data() {
        return {
            type:1,
            dialogVisible: false,
            loading:false,
            id: '',
            info:'',
            imgPreview: false,
            dialogImageUrl: '',
        }
    },
    computed: {
        title() {
            return 'KOL详情'
        },
        infoList1() {
            let infoList = []
            if (this.type == 1) {
                infoList = [
                    {
                        label: '用户ID：',
                        value: this.info.member_id
                    },
                    {
                        label: '身份：',
                        value: '个人'
                    },
                    {
                        label: '登录手机号：',
                        value: this.info.member?.mobile
                    },
                    {
                        label: '注册来源：',
                        value: this.info.member?.source_name || '--',
                    },
                    {
                        label: '姓名：',
                        value: this.info.name,
                    },
                    {
                        label: '身份证号：',
                        value: this.info.idc
                    },
                ]
            }
            if (this.type == 2) {
                infoList = [
                    {
                        label: '用户ID：',
                        value: this.info.member_id
                    },
                    {
                        label: '身份：',
                        value: '企业'
                    },
                    {
                        label: '登录手机号：',
                        value: this.info.member?.mobile
                    },
                    {
                        label: '注册来源：',
                        value: this.info.member?.source_name || '--',
                    },
                    {
                        label: '企业名称：',
                        value: this.info.corp_name,
                    },
                    {
                        label: '统一社会信息代码：',
                        value: this.info.credit_code,
                    },
                    {
                        label: '法人代表姓名：',
                        value: this.info.name,
                    },
                    {
                        label: '法人代表身份证号：',
                        value: this.info.idc
                    },
                ]
            }
            return infoList
        },
        infoList2() {
            let infoList = [
                {
                    label: '视频平台及账号ID：',
                    value: this.info.video_account,
                },
                {
                    label: 'PC版个人主页链接：',
                    value: this.info.home_url,
                },
                {
                    label: '推荐人：',
                    value: this.info.referrer_mobile||'无',
                },
                {
                    label: '签约：',
                    value: this.info.sign_url
                },
                {
                    label: '上传视频平台粉丝数量截图：',
                    value: this.info.fan_screen,
                },
            ]
            return infoList
        },
        infoList3() {
            let infoList = [
                {
                    label: '注册时间：',
                    value: this.info.member?.register_time,
                    show:true,
                },
                {
                    label: '提交入驻时间：',
                    value: this.info.create_time,
                    show:true,
                },
                {
                    label: '有效期时间段：',
                    value: this.info.kol_started+'至'+this.info.kol_ended,
                    show:this.info.kol_started?true:false,
                },
            ]
            return infoList
        },
        infoList4() {
            let infoList = [
                {
                    label: '拒绝原因：',
                    value: this.info.remarks || ''
                },
            ]
            return infoList
        },
    },
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开弹框
        openDialogBtn(id) {
            this.dialogVisible = true
            this.id = id
            this.getDetails()
        },
        getDetails() {
            this.loading = true
            this.$userApi.getKolApplyDetails(this.id).then(res => {
             this.loading = false
            	if (res.code == 1000) {
            		this.info = res.result
            		this.type = res.result.type
            	} else {
            		this.$errMsg(res.message)
             }
            })
        },
        // 取消
        cancelBtn() {
            this.id=''
            this.info=''
            this.dialogImageUrl=''
            this.dialogVisible = false
        },
        // 图片预览
        handleImgPreview(url) {
            this.dialogImageUrl = url;
            this.imgPreview = true;
        },
        // 复制
        copyBtn(val) {
            this.$copyText(val).then(
                (e) => {
                    this.$message.success("复制成功");
                },
                function (e) { }
            );
        },
    },
}
</script>

<style lang='scss' scoped>
.kolEdit {
    .details_info {

        .info_box {
            display: flex;
            flex-wrap: wrap;

            .info_item {
                display: flex;
                width: 50%;
                margin-bottom: 20px;

                .label {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }

                .label1 {
                    width: 84px;
                }

                .label2 {}

                .label3 {
                    width: 98px;
                }
                .label4 {
                    width: 126px;
                }

                .value {
                    flex: 1;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    display: flex;
                    align-items: center;

                    .img {
                        width: 130px;
                        height: 86px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .copy {
                        cursor: pointer;
                        width: 16px;
                        height: 16px;
                        margin-left: 5px;
                    }
                }
            }

            .time_box {
                display: flex;
                align-items: flex-start;
                margin-bottom: 20px;

                .label {
                    width: 98px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }

                .value {
                    flex: 1;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                }
            }
        }

        .line {
            width: 674px;
            height: 2px;
            background: #D8D8D8;
            margin: 10px 0 30px 0;
        }
    }
}
</style>
