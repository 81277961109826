<!-- @Author: Yu_Bo -->
<template>
  <div class=''>
    <div class="table_wrap height">
      <el-table ref="table1" key="table1" :data="list" v-loading="loading" style="width: 100%"
        header-cell-class-name="table-header" cell-class-name="cell-class" @selection-change="handleSelectionChange">
        <template slot="empty">
          <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
        </template>
        <el-table-column type="selection" align="center" width="55" fixed="left">
        </el-table-column>
        <el-table-column prop="member_id" label="用户ID" width="100" fixed="left">
        </el-table-column>
        <el-table-column prop="mobile" label="登录手机账号" width="150" fixed="left">
        </el-table-column>
        <el-table-column prop="" label="名称" width="145" fixed="left" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="flex_box">
              <div v-if="scope.row.type == 1" class="isPerson">个人</div>
              <div v-if="scope.row.type == 2" class="isCompony">企业</div>
              <div class="flex_box">{{scope.row.type == 1?scope.row.nickname:scope.row.corp_name}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="idc" label="身份证号" width="150" fixed="left" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="name" label="姓名(法人)" width="150">
        </el-table-column>
        <el-table-column prop="" label="统一社会信息代码" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{scope.row.credit_code || '--'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="video_account" label="视频平台及账号ID" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="referrer_mobile" label="推荐人" width="120">
          <template slot-scope="scope">
            <span>{{scope.row.referrer_mobile || '无'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="source_name" label="注册来源" width="120">
        </el-table-column>
        <el-table-column prop="" label="审核状态" width="120">
          <template slot-scope="scope">
            <div v-if="scope.row.status == 1" class="txt_warning">待审核</div>
            <div v-if="scope.row.status == 2" class="txt_succ">已审核</div>
            <div v-if="scope.row.status == 3" class="txt_error">已拒绝</div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="签名" width="120">
          <template slot-scope="scope">
            <el-link :href='scope.row.sign_url' type="primary">电子签约</el-link>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" v-if="scope.row.status == 1" @click="kolAuditBtn(scope.row.id)">
              审核
            </el-button>
            <el-button type="text" size="small" v-else @click="kolAuditDetailBtn(scope.row.id)">
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
  import EmptyData from '@/components/empty_data/empty_data.vue' //空数据

  export default {
    components: {
      EmptyData,
    },
    props: {
      list: {
        type: Array,
        default: () => []
      },
      loading: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        multipleSelection: []
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      // kol审核
      kolAuditBtn(id) {
        this.$emit('kolAuditBtn', id)
      },
      // kol审核详情
      kolAuditDetailBtn(id) {
        this.$emit('kolAuditDetailBtn', id)
      },
      handleSelectionChange(val) {
        this.multipleSelection = val
        this.$emit('idsBtn', val)
      }
    },
  }
</script>

<style lang='scss' scoped>
  @import "./index.scss";
</style>
